import { LiveAnnouncer } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { members } from '../../../models/organisation.model';

@Component({
  standalone: true,
  imports: [CommonModule, MatTableModule, MatSortModule, TranslateModule],
  selector: 'advocate-ui-members-table',
  templateUrl: './members-table.component.html',
  styleUrls: ['./members-table.component.scss']
})
export class MembersTableComponent {
  displayedColumns: string[] = ['name', 'email'];
  dataSource = new MatTableDataSource<members>();
  @Input() set data(data: members[] | null) {
    if (data !== null) {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sort = this.sort;
    }
  }

  constructor(private route: Router, private _liveAnnouncer: LiveAnnouncer) { }

  @ViewChild(MatSort) sort!: MatSort;

  navigateToHome(): void {
    this.route.navigate(['/home'])
  }

  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
}