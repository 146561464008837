import { AdvertiserItemModel } from "./advertiser.model";
import { ChipsModel } from "./chips.model";

export interface Campaign {
    id: number;
    name: string;
    status_id: number;
    status_label: string;
    platform_id: number;
    platform_label: string;
    type_id: number;
    type_label: string;
    advertiser_id: number | null;
    advertiser: AdvertiserItemModel;
    objective_id: number;
    objective_label: string;
    creatives: Creative[];
    start_date: string;
    end_date: string;
    start_time: string;
    end_time: string;
    start_timezone_id: number;
    start_timezone_label: string;
    end_timezone_id: number;
    end_timezone_label: string;
    lifetime_budget: number;
    budget_distribution_id: number;
    budget_distribution_label: string;
    daily_budget: number | null;
    running_days: string[];
    running_time_from: string;
    running_time_to: string;
    targeting: Targeting;
    lifetime_data: LifetimeData;
    flags: CampaignFlags;
}

interface LifetimeData {
    spend: number;
    impressions: number;
    clicks: number;
    vcr: number;
    ctr: number;
    cpm: number;
    cpc: number;
}

interface CampaignFlags {
    can_pause: boolean;
    can_archive: boolean;
    can_unarchive: boolean;
    is_archived: boolean;
    can_remove_creatives: boolean;
}

export interface Keywords {
    active: boolean,
    id: number,
    keyword: string,
    match_type_id: number,
    match_type_label: string,
    type_id: number,
    type_label: string
}

export interface Creative {
    id?: number;
    name?: string;
    type_id?: number;
    type_label?: string;
    status_id?: number;
    status_label?: string;
    file_id?: number;
    sponsored_by?: string;
    landing_url?: string;
    headline?: string;
    text?: string;
    retargeting?: boolean;
    video_url?: string;
    display_url?: string;
    headline_1?: string;
    headline_2?: string;
    headline_3?: string;
    description_1?: string;
    description_2?: string;
    additional_headlines?: string[];
    additional_description?: string[];
    blobData?: Blob | null;
    call_to_action_text?: string;
}

export interface Targeting {
    demographic_countries: string[];
    locations: Location[];
    zip_codes: string[];
    geofencing_file_id: number | null;
    age_groups: string[];
    genders: string[];
    interests: string[];
    keyword_language: string | null;
    whitelisted_keywords: string[];
    blacklisted_keywords: string[];
    whitelisted_domains: string[];
    blacklisted_domains: string[];
    devices: string[];
    languages: Location[];
    education: string[];
    proximity_targets: ProximityValues[];
    is_political: boolean;
    environments: string[];
    frequency_cap?: number;
    frequency_cap_interval?: number;
    frequency_cap_interval_type_id?: number;
    keywords: Keywords[];
    negative_keywords: Keywords[];
    audience_id?: number;
    has_premium_in_stream_video?: boolean | null;
    has_premium_mobile_apps_only?: boolean | null;
    has_premium_non_skippable_video?: boolean | null;
    has_premium_placements?: boolean | null;
    contract_ids?: number[];
    geofence_ids?: number[];
    congressional_district_ids?: number[];
}

export interface Location {
    id: number;
    name: string;
}

export interface ProximityValues {
    active: boolean,
    address: string,
    id: number,
    latitude: number,
    longitude: number,
    radius: number,
    radius_unit_id: number,
    radius_unit_label: string
}

export interface LoctionsResponse {
    locations: Location[];
}

export interface CampaignsResponse {
    campaigns: {
        items: Campaign[];
        _links: {
            self: { href: string };
            first: { href: string };
            last: { href: string };
        };
        _meta: {
            totalCount: number;
            pageCount: number;
            currentPage: number;
            perPage: number;
        };
    }
}

export interface CampaignGroupsTableInterface {
    name: string;
    campaign: boolean;
    type: string;
    impressions: number;
    cpm: number;
    cpc: number;
    vcr: number;
    ctr: number;
    flight_start_date: string;
    flight_end_date: string;
    lifetime_budget: number;
    daily_budget: number;
    edit: string;
    status_label: string;
}

export interface CampaignItemInfo {
    campaign: {
        id: number;
        name: string;
        status_id: number;
        status_label: string;
        platform_id: number;
        platform_label: string;
        type_id: number;
        type_label: string;
        advertiser_id: number | null;
        advertiser: AdvertiserItemModel;
        objective_id: number;
        objective_label: string;
        creatives: Creative[];
        start_date: string;
        end_date: string;
        start_time: string;
        end_time: string;
        start_timezone_id: number;
        start_timezone_label: string;
        end_timezone_id: number;
        end_timezone_label: string;
        lifetime_budget: number;
        budget_distribution_id: number;
        budget_distribution_label: string;
        daily_budget: number | null;
        running_days: string[];
        running_time_from: string;
        running_time_to: string;
        targeting: Targeting;
        lifetime_data: LifetimeData;
        flags: CampaignFlags;
        max_bid?: number;
        bid?: number;
        cpc_bid?: number
    }

}

export interface CampaignDetailsInterface {
    advertiser_name: ChipsModel[],
    name: ChipsModel[],
    objective: ChipsModel[],
    pacing: ChipsModel[],
    genders: ChipsModel[],
    age_groups: ChipsModel[],
    education: ChipsModel[],
    running_days: ChipsModel[],
    budget: ChipsModel[],
    start_date_time: ChipsModel[],
    end_date_time: ChipsModel[],
    running_time_from: ChipsModel[],
    running_time_to: ChipsModel[],
    saved_audience: ChipsModel[],
    advance_targeting: ChipsModel[],
    zip_codes: ChipsModel[],
    locations: ChipsModel[],
    whitelisted_keywords: ChipsModel[],
    blacklisted_keywords: ChipsModel[],
    whitelisted_domains: ChipsModel[],
    blacklisted_domains: ChipsModel[],
    contracts: ChipsModel[],
    geofences: { url: string; filename: string; mimeType?: string | undefined; } | null;
    predefined_geofences: ChipsModel[]
}

export interface campaignActionResponse {
    name: string;
    message: string;
    code: number;
    status: number;
    type: string;
}

export interface CampaignForecastResponse {
    currency_id: number;
    currency_label: string;
    impression_high: number;
    impression_high_pretty: string;
    impression_low: number;
    impression_low_pretty: string;
    price_high: number;
    price_low: number;
}

export interface GeoFenceListResponse {
    all: GeofenceDataObject[];
    country: GeofenceDataObject[];
    global: GeofenceDataObject[];
    organisation: GeofenceDataObject[];
}

export interface GeofenceDataObject {
    id: number;
    misc: [];
    name: string;
    platform_id: number;
    platform_label: string;
    type_id: number;
    type_label: string;
}