import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';


@Component({
  selector: 'advocate-ui-payment',
  standalone: true,
  imports: [CommonModule, MatTableModule, TranslateModule],
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent {
  displayedColumns: string[] = ['header', 'details'];
  dataSource = new MatTableDataSource<{header: string, details: string}>();
  @Input() set data(data: {header: string, details: string}[] | null) {
    if(data !== null) {
      this.dataSource = new MatTableDataSource(data);
    }
  }
}