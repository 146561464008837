import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { environment } from '../environments/environment';
import { AdvocateOption, CampaignForecastResponse, CampaignGetReponseData, CampaignItemInfo, CampaignPostResponseData, CampaignSteps, CampaignsResponse, Creative, CreativesResponse, FilesResponse, GeoFenceListResponse, ProximityTarget, ProximityTargetResponse, Step0, campaignActionResponse } from '../models';

@Injectable({
    providedIn: 'root',
})
export class CampaignsService {
    campaignId$ = new BehaviorSubject<number | null>(null);
    campaignName = signal('');

    private getUrlsState$ = new BehaviorSubject<GeoFenceListResponse | null>(null);
    private getGeofencesState$ = new BehaviorSubject<GeoFenceListResponse | null>(null);

    getCampaignIdState(): Observable<number | null> {
        return this.campaignId$.asObservable();
    }

    private apiUrl = environment.apiKey;; // Replace with your API URL
    constructor(private http: HttpClient) { }

    getAllCampaigns(orgId: number, includeArchiveFlag: number, page?: number, per_page?: number, advId?: number, typeId?: number, cname?: string, status_id?: number): Observable<CampaignsResponse> {
        const url = `${this.apiUrl}/organisations/${orgId}/campaigns`;
        const params: any = {};
        params.include_archived = includeArchiveFlag;
        if (page) params.page = page;
        if (per_page) params.per_page = per_page;
        if (advId && advId !== 0) params.advertiser_id = advId;
        if (typeId && typeId !== 0) params.type_id = typeId;
        if (cname) params.name = cname;
        if (status_id && status_id !== 0) params.status_id = status_id;

        return this.http.get<CampaignsResponse>(url, { params });
    }

    setUrlsState(state: GeoFenceListResponse | null): void {
        this.getUrlsState$.next(state);
    }

    setGeofencesState(state: GeoFenceListResponse | null): void {
        this.getGeofencesState$.next(state);
    }

    getUrlsState(): Observable<GeoFenceListResponse | null> {
        return this.getUrlsState$;
    }

    getGeofencesState(): Observable<GeoFenceListResponse | null> {
        return this.getGeofencesState$;
    }

    createCampaign(orgId: number, body: { step0: Step0 }): Observable<CampaignPostResponseData> {
        return this.http.post<CampaignPostResponseData>(`${this.apiUrl}/organisations/${orgId}/campaigns`, body)
            .pipe(
                tap((t: CampaignPostResponseData) => this.campaignId$.next(t.campaign.step0.id ?? 0))
            )
    }

    getCampaignSteps(orgId: number, campaignId: number): Observable<CampaignGetReponseData> {
        return this.http.get<CampaignGetReponseData>(`${this.apiUrl}/organisations/${orgId}/campaigns/${campaignId}/steps`)
    }

    updateCampaignStep(orgId: number, campaignId: number, body: CampaignSteps): Observable<any> {
        return this.http.patch<any>(`${this.apiUrl}/organisations/${orgId}/campaigns/${campaignId}/steps`, body)
    }

    uploadCreativeFile(orgId: number, body: FormData): Observable<FilesResponse | HttpErrorResponse> {
        return this.http.post<FilesResponse>(`${this.apiUrl}/organisations/${orgId}/files`, body)
    }

    getCreativeFile(orgId: number, fileId: number): Observable<Blob> {
        const options = {
            responseType: 'blob' as 'json',
        };
        return this.http.get<Blob>(`${this.apiUrl}/organisations/${orgId}/files/${fileId}`, options);
    }

    getCampaignData(orgId: number, id: number): Observable<CampaignItemInfo> {
        this.campaignId$.next(id);
        return this.http.get<CampaignItemInfo>(`${this.apiUrl}/organisations/${orgId}/campaigns/${id}`)
    }

    actionCampaignEffect(orgId: number, campaignId: number, action: string): Observable<campaignActionResponse> {
        const body = { action };
        return this.http.post<campaignActionResponse>(`${this.apiUrl}/organisations/${orgId}/campaigns/${campaignId}/actions`, body);
    }

    duplicateCampaign(orgId: number, campaignId: number, action: string, platformId?: number): Observable<CampaignItemInfo> {
        const body = {
            action,
            ...(platformId ? { platform_id: platformId } : {}),
        };
        return this.http.post<CampaignItemInfo>(`${this.apiUrl}/organisations/${orgId}/campaigns/${campaignId}/actions`, body);
    }

    creativeAction(orgId: number, campaignId: number, creativeId: number, action: string): Observable<{ creative: Creative }> {
        const body = { action };
        return this.http.post<{ creative: Creative }>(`${this.apiUrl}/organisations/${orgId}/campaigns/${campaignId}/creatives/${creativeId}/actions`, body);
    }

    createProximity(orgId: number, campaignId: number, body: ProximityTarget): Observable<ProximityTargetResponse> {
        return this.http.post<ProximityTargetResponse>(`${this.apiUrl}/organisations/${orgId}/campaigns/${campaignId}/proximity-targets`, body)
    }

    getCreativesOfCampaign(orgId: number, campaignId: number): Observable<CreativesResponse> {
        return this.http.get<CreativesResponse>(`${this.apiUrl}/organisations/${orgId}/campaigns/${campaignId}/creatives`);
    }

    getCampaignsForecast(orgId: number, campaignId: number): Observable<CampaignForecastResponse> {
        return this.http.get<CampaignForecastResponse>(`${this.apiUrl}/organisations/${orgId}/campaigns/${campaignId}/forecast`);
    }

    getCampaignsGeofence(orgId: number, campaignId: number, countryNames?: AdvocateOption[]): Observable<GeoFenceListResponse> {
        let params = new HttpParams();
        countryNames?.forEach(m => {
            params = params.append('demographic_countries[]', m.id);
        });
        return this.http.get<GeoFenceListResponse>(`${this.apiUrl}/organisations/${orgId}/campaigns/${campaignId}/geofences`, { params });;
    }

    getCampaignsUrls(orgId: number, campaignId: number, countryNames?: AdvocateOption[]): Observable<GeoFenceListResponse> {
        let params = new HttpParams();
        countryNames?.forEach(m => {
            params = params.append('demographic_countries[]', m.id);
        });
        return this.http.get<GeoFenceListResponse>(`${this.apiUrl}/organisations/${orgId}/campaigns/${campaignId}/website-lists`, { params }).pipe(tap(t => this.setUrlsState(t)));
    }

    getCongressionalDistrict(orgId: number, campaignId: number): Observable<GeoFenceListResponse> {
        let params = new HttpParams();
        params = params.append('demographic_countries[]', 'United States');
        return this.http.get<GeoFenceListResponse>(`${this.apiUrl}/organisations/${orgId}/campaigns/${campaignId}/congressional-districts`, { params });
    }
}