import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../../shared/icon/icon.component';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, IconComponent, MatButtonModule, TranslateModule],
  selector: 'advocate-ui-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent {
  optionSelected = false;

  hoveredNewCampaigns = false;
  hoveredCampaigns = false;
  hoveredOrganisation = false;

  constructor(private route: Router) { }

  navigateTo(_route: string): void {
    this.optionSelected = true;
    this.route.navigate([_route]);
  }
}
