<div class="container">
    <div class="breadcrumbs">
        <span class="breadcrumbs--in-active" (click)="navigateToHome()">{{ "GENERAL.HOME" | translate }}</span>
        <span class="breadcrumbs--active">/</span>
        <span class="breadcrumbs--active">{{ "CAMPAIGN.ORG_GROUPS" | translate }}</span>
    </div>
    <div class="container-body">
        <div class="container-body--members">
            <advocate-ui-members-table [data]="memberDataSource$ | async"></advocate-ui-members-table>
        </div>
        <div class="container-body--payment">
            <advocate-ui-payment [data]="paymentDataSource$ | async"></advocate-ui-payment>
        </div>

        <div class="container-body--advertisers">
            <div class="container-body--tabs">
                <advocate-ui-button state="primary"
                    [name]="('GENERAL.ADVERTISER' | translate) + 's'"></advocate-ui-button>
                <advocate-ui-button *ngIf="managedModeOff() && !readOnlyUser()" class="add-adv-button" [isCurved]="true"
                    state="secondary" size="large" [name]="'+' + ('CAMPAIGN.ADD_ADVERTISERS' | translate)"
                    (click)="addAdvertisers()"></advocate-ui-button>
            </div>

            <div class="container-body--table">
                <advocate-ui-advertisers-table [data]="data"></advocate-ui-advertisers-table>
            </div>
        </div>
    </div>
</div>