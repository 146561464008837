import { AdvertiserItemModel } from "./advertiser.model";
import { AudiencesResponse } from "./audience.model";

export interface UserData {
    two_factor_qr_svg: string;
    user: {
        id: number;
        email: string;
        name: string;
        phone: string;
        two_factor_enabled: boolean;
        organisation: {
            id: number;
            name: string;
            members: string[];
            advertisers: AdvertiserItemModel[];
            audiences: AudiencesResponse[],
            managed_mode_id: number;
            managed_mode_label: string;
            payment_method_id: number;
            payment_method_label: string;
            payment_information: string;
            currency_id: number;
            currency_label: string;
            debit: number;
            spend: number;
            balance: number;
            country_label: string;
            country_id: number;
            flags: {
                is_political: boolean,
                can_political: boolean
            }
        };
        preferences: { language_id: number, language_label: string };
    };
}