<div class="container">
    <div class="container__login">
        <div class="login_panel">
            <div class="left-panel">
                <div class="left-panel__header">
                    <div class="left-panel__wrapper">
                        <p class="left-panel__header__login left-panel__header__active">{{ "AUTH.RESET_PASSWORD" | translate }}</p>
                    </div>
                </div>

                <div class="left-panel__body">
                    <form [formGroup]="resetPasswordForm" class="advocate-form">
                        <div class="advocate-form-row">
                            <div class="advocate-form-row__label advocate-form-label">
                                <label for="new_password" class="advocate-form-label__content">{{ "PASSWORD.NEW_PASSWORD" | translate }}</label>
                                <span class="advocate-required-field">&nbsp;</span>
                            </div>

                            <div class="advocate-form-row__content">
                                <input type="text" id="new_password" autocomplete="off" formControlName="new_password"
                                    class="cux-textbox cux-textbox--stretched" [placeholder]="'PASSWORD.NEW_PASSWORD' | translate"
                                    [ngClass]="{'cux-textbox--error': resetPasswordForm.get('new_password')?.errors?.['required'] && resetPasswordForm.get('new_password')?.touched}">

                                <div class="advocate-help-text"
                                    *ngIf="resetPasswordForm.get('new_password')?.errors?.['required'] && resetPasswordForm.get('new_password')?.touched">
                                    <p class="advocate-help-text--error">{{ "PASSWORD.NEW_PASSWORD_REQ" | translate }}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="advocate-form-row">
                            <div class="advocate-form-row__label advocate-form-label">
                                <label for="repeat_new_password" class="advocate-form-label__content">{{ "PASSWORD.REPEAT_NEW_PASSWORD" | translate }}</label>
                                <span class="advocate-required-field">&nbsp;</span>
                            </div>

                            <div class="advocate-form-row__content">
                                <input id="repeat_new_password" autocomplete="off" formControlName="repeat_new_password"
                                    class="cux-textbox cux-textbox--stretched" [placeholder]="'PASSWORD.REPEAT_NEW_PASSWORD' | translate"
                                    [ngClass]="{'cux-textbox--error': (resetPasswordForm.get('repeat_new_password')?.errors?.['required'] || resetPasswordForm.get('repeat_new_password')?.errors?.['passwordMismatch']) && resetPasswordForm.get('repeat_new_password')?.touched}">
                            </div>
                            <div class="advocate-help-text"
                                *ngIf="resetPasswordForm.get('repeat_new_password')?.errors?.['required'] && resetPasswordForm.get('repeat_new_password')?.touched">
                                <p class="advocate-help-text--error">{{ "PASSWORD.REPEAT_NEW_PASSWORD_REQ" | translate }}
                                </p>
                            </div>

                            <div class="advocate-help-text"
                                *ngIf="resetPasswordForm.get('repeat_new_password')?.errors?.['passwordMismatch'] && resetPasswordForm.get('repeat_new_password')?.touched">
                                <p class="advocate-help-text--error"> {{ "PASSWORD.PASSWORD_MATCH" | translate }}
                                </p>
                            </div>

                            <div class="advocate-help-text" *ngIf="resetPasswordErrorSignal()">
                                <p class="advocate-help-text--error">{{resetPasswordErrorSignal()}}
                                </p>
                            </div>
                        </div>

                        <div class="advocate-submit-row">
                            <div class="form-submission">
                                <advocate-ui-button [isCurved]="true" [name]="'GENERAL.RESET' | translate"
                                    [disabled]="resetPasswordForm.invalid"
                                    [state]="resetPasswordForm.invalid ? 'Disabled' : 'secondary'"
                                    (onChange)="resetPassword()"></advocate-ui-button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="right-panel">
                <div class="right-panel__header">
                    <h3 class="right-panel__header--text">{{ 'AUTH.WELCOME_TO' | translate }} </h3>
                    <div class="right-panel__header__wrapper">
                        <img src="../../assets/advocate-ui-logo.png" class="right-panel__header__wrapper--logo">
                    </div>
                </div>
                <div class="right-panel__body">
                    <img src="../../assets/login-screen-image.png" alt="Login Screen logo" />
                </div>
            </div>
        </div>
    </div>
</div>