<div class="container">
  <button class="close-button" (click)="closeModal()">X</button>
  <h2 class="container_header">{{ "HEADER.CONTACT_US" | translate }}</h2>
  <form class="advocate-form" [formGroup]="form">
    <div class="advocate-form-row">
      <div class="advocate-form-row__label advocate-form-label">
        <label for="name" class="advocate-form-label__content">{{ "GENERAL.NAME" | translate }}</label>
        <span class="advocate-required-field">&nbsp;</span>
      </div>

      <div class="advocate-form-row__content">
        <input type="text" id="name" autocomplete="off" class="cux-textbox cux-textbox--stretched"
          formControlName="name" required />
      </div>
    </div>

    <div class="advocate-form-row">
      <div class="advocate-form-row__label advocate-form-label">
        <label for="subject" class="advocate-form-label__content">{{ "GENERAL.SUBJECT" | translate }}</label>
        <span class="advocate-required-field">&nbsp;</span>
      </div>

      <div class="advocate-form-row__content">
        <input type="text" id="subject" autocomplete="off" class="cux-textbox cux-textbox--stretched"
          formControlName="subject" required />
      </div>
    </div>

    <div class="advocate-form-row">
      <div class="advocate-form-row__label advocate-form-label">
        <label for="phone" class="advocate-form-label__content">{{ "GENERAL.PHONE" | translate }}</label>
      </div>

      <div class="advocate-form-row__content">
        <input type="number" id="phone" autocomplete="off" class="cux-textbox cux-textbox--stretched"
          formControlName="phone" />
      </div>
    </div>

    <div class="advocate-form-row">
      <div class="advocate-form-row__label advocate-form-label">
        <label for="email" class="advocate-form-label__content">{{ "GENERAL.EMAIL" | translate }}</label>
        <span class="advocate-required-field">&nbsp;</span>
      </div>

      <div class="advocate-form-row__content">
        <input type="email" id="email" autocomplete="off" class="cux-textbox cux-textbox--stretched"
          formControlName="email" required />
      </div>
    </div>

    <div class="advocate-form-row">
      <div class="advocate-form-row__label advocate-form-label">
        <label for="message" class="advocate-form-label__content">{{ "GENERAL.MESSAGE" | translate }}</label>
        <span class="advocate-required-field">&nbsp;</span>
      </div>

      <div class="advocate-form-row__content">
        <textarea id="message" name="message" rows="10" class="cux-textbox cux-textbox--stretched textbox"
          formControlName="message" required></textarea>
      </div>
    </div>

    <div class="advocate-form-row" *ngIf="selectedOption === ContactUsEnum.Request">
      <div class="advocate-form-row__label advocate-form-label">
        <label for="note" class="advocate-form-label__content">{{ "GENERAL.NOTE" | translate }}</label>
      </div>

      <div class="advocate-form-row__content">
        <textarea id="note" name="note" rows="10" class="cux-textbox cux-textbox--stretched"
          formControlName="note"></textarea>
      </div>
    </div>
    <div class="advocate-form-row__button">
      <advocate-ui-button [isCurved]="true" state="primary" size="small" [name]="'GENERAL.SEND' | translate"
        (click)="sendEnquiry()"></advocate-ui-button>
    </div>
  </form>
</div>