<div class="container" *ngIf="{ info: (userInfo$ | async)} as vm">
  <div class="breadcrumbs">
    <span class="breadcrumbs--in-active" (click)="navigateHome()">{{ "GENERAL.HOME" | translate }}</span>
    <span class="breadcrumbs--active">/</span>
    <span class="breadcrumbs--active">{{ "HOME.NEW_CAMPAIGN" | translate }}</span>
  </div>
  <ng-container *ngIf="managedModeOff() && !readOnlyUser(); else noContent">
    <div class="container_header">
      <span>{{ "CAMPAIGN.SELECT_PLATFORM" | translate }}</span>
    </div>
    <div data-cy="campaignType-options" class="container-body">
      <div class="container-body--options" [class.container-body--options-selected]="tabOptions[0].selected"
        [class.container-body--disabled]="vm.info?.user?.organisation?.flags?.is_political && !vm.info?.user?.organisation?.flags?.can_political"
        #tooltip="matTooltip" [matTooltip]="'CAMPAIGN.PROGRAMMATIC_DES' | translate"
        matTooltipPosition="right" (mouseenter)="tabOptions[0].hovered = true"
        (mouseleave)="tabOptions[0].hovered = false" (click)="tabSelected(tabOptions[0])">
        <div class="container-body--options-icon icon1">
          <img *ngIf="!isHoveredOrSelected(tabOptions[0])" src="../../assets/icons/computer-blue.svg" />
          <img *ngIf="isHoveredOrSelected(tabOptions[0])" src="../../assets/icons/computer.svg" />
        </div>

        <div class="container-body--options-name">
          <span>{{tabOptions[0].tabName}}</span>
        </div>
      </div>

      <div class="container-body--options" [class.container-body--options-selected]="tabOptions[1].selected"
        [class.container-body--disabled]="vm.info?.user?.organisation?.flags?.is_political && !vm.info?.user?.organisation?.flags?.can_political"
        (mouseenter)="tabOptions[1].hovered = true" (mouseleave)="tabOptions[1].hovered = false"
        (click)="tabSelected(tabOptions[1])" #tooltip="matTooltip"
        [matTooltip]="'CAMPAIGN.SEARCH_DES' | translate" matTooltipPosition="right">
        <div class="container-body--options-icon">
          <img *ngIf="!isHoveredOrSelected(tabOptions[1])" src="../../assets/icons/search-blue.svg" />
          <img *ngIf="isHoveredOrSelected(tabOptions[1])" src="../../assets/icons/search-white.svg" />
        </div>

        <div class="container-body--options-name">
          <span>{{tabOptions[1].tabName}}</span>
        </div>
      </div>
      <div class="container-body--options" [class.container-body--options-selected]="tabOptions[2].selected"
        [class.container-body--disabled]="vm.info?.user?.organisation?.flags?.is_political && !vm.info?.user?.organisation?.flags?.can_political"
        (mouseenter)="tabOptions[2].hovered = true" (mouseleave)="tabOptions[2].hovered = false"
        (click)="tabSelected(tabOptions[2])" #tooltip="matTooltip" [matTooltip]="'CAMPAIGN.YOUTUBE_DES' | translate" matTooltipPosition="right">
        <div class="container-body--options-icon">
          <img *ngIf="!isHoveredOrSelected(tabOptions[2])" src="../../assets/icons/youtube-blue.svg" />
          <img *ngIf="isHoveredOrSelected(tabOptions[2])" src="../../assets/icons/youtube-white.svg" />
        </div>

        <div class="container-body--options-name">
          <span>{{tabOptions[2].tabName}}</span>
        </div>
      </div>
    </div>

    <div *ngIf="errorSignals()" class="stepper-error-disclaimer mt-3">
      <span> {{errorSignals()}} </span>
    </div>

    <div *ngIf="vm.info?.user?.organisation?.flags?.is_political && !vm.info?.user?.organisation?.flags?.can_political"
      class="stepper-error-disclaimer mt-3">
      <span>
        You can't create political campaigns yet, please
        <a href="#" (click)="navigateToVerification($event)">verify your organisation</a>
        and try again.
      </span>
    </div>
  </ng-container>
  <ng-template #noContent>
    <ng-container *ngIf="!readOnlyUser(); else readAccess">
      <h2>{{ "CAMPAIGN.MANAGED_SERVICE_TEXT" | translate }}</h2>
    </ng-container>
    <ng-template #readAccess>
      <h2>{{ "CAMPAIGN.READ_ONLY_TEXT" | translate }}</h2>
    </ng-template>
  </ng-template>
</div>