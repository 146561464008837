<div class="left-panel__body">
    <div class="advocate-form">
        <!-- Name -->
        <div class="advocate-form-row">
            <div class="advocate-form-row__label advocate-form-label">
                <label for="email" class="advocate-form-label__content">{{ "GENERAL.NAME" | translate }}</label>
                <span class="advocate-required-field">&nbsp;</span>
            </div>

            <div class="advocate-form-row__content">
                <input type="text" id="name" autocomplete="off" class="cux-textbox cux-textbox--stretched"
                    [placeholder]="'GENERAL.NAME' | translate">
                <!-- <div class="advocate-help-text" *ngIf="form.get('description')?.invalid && form.get('description')?.touched">
                    <advocate-icon class="advocate-help-text--icon advocate-clear-filename__icon"
                                size="extra-small"
                                type="filled"
                                state="danger"
                                name="error"></advocate-icon>
                    <p class="advocate-help-text--error" i18n>&nbsp; Description field can not be empty.</p>
                </div> -->
            </div>
        </div>
        <!-- /Name -->

        <!-- Email -->
        <div class="advocate-form-row">
            <div class="advocate-form-row__label advocate-form-label">
                <label for="email" class="advocate-form-label__content">{{ "GENERAL.EMAIL" | translate }}</label>
                <span class="advocate-required-field">&nbsp;</span>
            </div>

            <div class="advocate-form-row__content">
                <input type="text" id="email" autocomplete="off" class="cux-textbox cux-textbox--stretched"
                    [placeholder]="'GENERAL.EMAIL' | translate">
                <!-- <div class="advocate-help-text" *ngIf="form.get('description')?.invalid && form.get('description')?.touched">
                    <advocate-icon class="advocate-help-text--icon advocate-clear-filename__icon"
                                size="extra-small"
                                type="filled"
                                state="danger"
                                name="error"></advocate-icon>
                    <p class="advocate-help-text--error" i18n>&nbsp; Description field can not be empty.</p>
                </div> -->
            </div>
        </div>
        <!-- /Email -->

        <div class="advocate-form-row">
            <div class="advocate-form-row__label advocate-form-label">
                <label for="password" class="advocate-form-label__content">{{ "GENERAL.PASSWORD" | translate }}</label>
                <span class="advocate-required-field">&nbsp;</span>
            </div>

            <div class="advocate-form-row__content">
                <input type="password" id="password" autocomplete="off" class="cux-textbox cux-textbox--stretched"
                    placeholder="'GENERAL.PASSWORD' | translate">
                <!-- <div class="advocate-help-text" >
                    <advocate-icon class="advocate-help-text--icon advocate-clear-filename__icon"
                                size="extra-small"
                                type="filled"
                                state="danger"
                                name="error"></advocate-icon>
                    <p class="advocate-help-text--error">&nbsp; Description field can not be empty.</p>
                </div> -->
            </div>
        </div>

        <!-- Confirm Password -->
        <div class="advocate-form-row">
            <div class="advocate-form-row__label advocate-form-label">
                <label for="password" class="advocate-form-label__content">{{ "AUTH.CONFIRM_PASSWORD" | translate }}</label>
                <span class="advocate-required-field">&nbsp;</span>
            </div>

            <div class="advocate-form-row__content">
                <input type="password" id="confirm-password" autocomplete="off"
                    class="cux-textbox cux-textbox--stretched" [placeholder]="'PASSWORD.RE_ENTER' | translate">
                <!-- <div class="advocate-help-text" >
                    <advocate-icon class="advocate-help-text--icon advocate-clear-filename__icon"
                                size="extra-small"
                                type="filled"
                                state="danger"
                                name="error"></advocate-icon>
                    <p class="advocate-help-text--error">&nbsp; Description field can not be empty.</p>
                </div> -->
            </div>
        </div>
        <!-- /Confirm Password -->


        <div class="advocate-submit-row">
            <div class="form-submission">
                <span class="form-submission--forgot-password">
                    <span>{{ "AUTH.ALREADY_MEMBER" | translate }} <span class="login_redirect" (click)="login.emit()">{{ "AUTH.LOGIN" | translate }}</span></span>
                </span>
                <advocate-ui-button class="form-submission--login-button" [isCurved]="true" [size]="buttonSize"
                    [state]="buttonState" [name]="'AUTH.CREATE_ACCOUNT' | translate">
                </advocate-ui-button>
            </div>
        </div>
    </div>
</div>