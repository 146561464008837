<ng-container *ngIf="tableData$ | async as data">
  <table mat-table [dataSource]="data.advertisers.items" matSort (matSortChange)="announceSortChange($event)"
    class="mat-elevation-z8 mat-mdc-table--spacing">

    <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

    <!-- name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> {{ "GENERAL.NAME" | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <!-- Number of campaigns Column -->
    <ng-container matColumnDef="num_of_campaigns">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> {{ "CAMPAIGN.NUMBER_OF_CAMPAIGNS" | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{element.campaign_count}} </td>
    </ng-container>

    <!-- Spend Column -->
    <ng-container matColumnDef="spend">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> {{ "GENERAL.SPEND" | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{element.spend}} </td>
    </ng-container>

    <!-- Balance Column -->
    <ng-container matColumnDef="balance">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> {{ "GENERAL.BALANCE" | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{element.balance}} </td>
    </ng-container>

    <!-- Edit Column -->
    <ng-container matColumnDef="edit">
      <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element; let i = index">
        <img *ngIf="managedModeOff() && !readOnlyUser()" src="../../assets/icons/edit-icon.svg"
          (click)="editAdvertiser(element.name, element.id, i)">
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-element-row"></tr>
  </table>

  <mat-paginator [length]="data.advertisers._meta.totalCount" [pageSize]="pageSize" [pageIndex]="pageIndex"
    (page)="onPageChange($event)" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons style="margin-bottom: 2rem;">
  </mat-paginator>
</ng-container>