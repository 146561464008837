import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, WritableSignal, signal } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LoginResponse } from '../../../models';
import { ButtonComponent } from '../../../shared/button/button.component';
import { IconComponent } from '../../../shared/icon/icon.component';
import { BackendErrorTranslate } from '../../../shared/pipes/backend-error-i18n.pipe';
import { UIState } from '../../../shared/utility';
import { AuthService } from '../auth.service';
import { SignupComponent } from '../signup/signup.component';

interface LoginError {
  email?: string[],
  password?: string[],
  two_factor_code?: string[],
  generalError?: string
}

@Component({
  selector: 'advocate-ui-login',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, IconComponent, SignupComponent, MatCheckboxModule, ButtonComponent, TranslateModule, BackendErrorTranslate],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  uiState = UIState.Primary;
  loginScreen = true;
  hidePassword: boolean = true;
  loginError: string = '';
  acceptPrivacy = false;

  show2FAtextBox: WritableSignal<boolean | null> = signal(null);
  loginErrorSignal: WritableSignal<LoginError | null> = signal(null);
  resetPasswordSignal: WritableSignal<string | null> = signal(null);

  loginForm: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
    two_factor_code: ['']
  });

  constructor(private authService: AuthService, private fb: FormBuilder, private route: Router) { }

  public loginScreenToggle(value: boolean): void {
    this.loginScreen = value;
  }

  togglePasswordVisibility() {
    this.hidePassword = !this.hidePassword;
  }
  // 'api@example.com', 'Y8GtHB9b4JaF'
  public login(): void {
    const two_factor_code: string | undefined = this.loginForm.get('two_factor_code')?.value ? this.loginForm.get('two_factor_code')?.value : undefined;
    this.authService.login(this.loginForm.get('email')?.value, this.loginForm.get('password')?.value, two_factor_code)
      .subscribe({
        next: (data: LoginResponse | { two_factor_required: boolean }) => {
          this.loginErrorSignal.set(null);
          if ('two_factor_required' in data && typeof data.two_factor_required === 'boolean') {
            this.show2FAtextBox.set(true);
            this.loginForm.addValidators(Validators.required);
            this.loginForm.updateValueAndValidity();
            return;
          }
          if (data) {
            this.loginError = '';
            this.route.navigate(['/home']);
          }
        },
        error: (error: HttpErrorResponse) => {
          console.warn('Login Error Encountered', error);
          if (error.status === 500) {
            this.loginErrorSignal.set({ generalError: error.error.message });
            return;
          }
          this.loginErrorSignal.set(error.error)
        }

      })
  }

  protected forgotPassword(): void {
    this.authService.forgotPassword(this.loginForm.get('email')?.value).subscribe({
      next: (res: string) => {
        this.loginErrorSignal.set(null);
        this.resetPasswordSignal.set(res);
      },
      error: (error: HttpErrorResponse) => {
        this.resetPasswordSignal.set(null);
        console.warn('Login Error Encountered', error);
        if (error.status === 500) {
          this.loginErrorSignal.set({ generalError: error.error.message });
          return;
        }
        this.loginErrorSignal.set(error.error)
      }
    })
  }
}