<div class="container">
  <h2 class="container_header">{{ dialogTitle }}</h2>
  <form class="advocate-form" [formGroup]="form">
    <div class="advocate-form-row">
      <div class="advocate-form-row__label advocate-form-label">
        <label for="name" class="advocate-form-label__content">{{ "GENERAL.NAME" | translate }}</label>
        <span class="advocate-required-field">&nbsp;</span>
      </div>

      <div class="advocate-form-row__content">
        <input type="text" id="name" autocomplete="off" formControlName="name"
          class="cux-textbox cux-textbox--stretched"
          [ngClass]="{'cux-textbox--error': errorSignal() && errorSignal()?.name?.[0]}" />

        <div class="advocate-help-text" *ngIf="errorSignal() && errorSignal()?.name">
          <p class="advocate-help-text--error">{{ errorSignal()?.name![0] | ErrorTranslate}}
          </p>
        </div>
      </div>
    </div>

    <div class="advocate-form-row__button">
      <advocate-ui-button [isCurved]="true"
        [state]="form.invalid ? 'Disabled' : data.mode === 'add' ? 'primary' : 'secondary'" size="small"
        name="{{data.mode === 'add' ? ('GENERAL.ADD' | translate) : ('GENERAL.SAVE' | translate) }}"
        (click)="saveEditedData()" [disabled]="form.invalid"></advocate-ui-button>
    </div>
  </form>
</div>