import { Component, EventEmitter, Output } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonSize } from '../../../shared/button/button-size.enum';
import { ButtonComponent } from '../../../shared/button/button.component';
import { IconComponent } from '../../../shared/icon/icon.component';
import { UIState } from '../../../shared/utility';

@Component({
  selector: 'advocate-ui-signup',
  standalone: true,
  imports: [IconComponent, MatCheckboxModule, ButtonComponent, TranslateModule],
  templateUrl: './signup.component.html',
  styleUrls: ['../login/login.component.scss']
})
export class SignupComponent {
  buttonState = UIState.Primary;
  buttonSize = ButtonSize.Large;

  @Output()
  login = new EventEmitter<void>();
}