import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'ErrorTranslate',
    standalone: true
})
export class BackendErrorTranslate implements PipeTransform {

    constructor(private translateService: TranslateService) { }

    transform(errorString: string): string {
        return this.translateService.instant('BE.' + errorString);
    }
}