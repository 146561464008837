import { CommonModule, DatePipe } from '@angular/common';
import { Component, Input, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { UtilityViewModelService } from '../../../app/view-model/utility-vm.service';
import { AdvocateCurrencyOptions, MetricsModel } from '../../../models';

@Component({
    selector: 'advocate-ui-matrics',
    standalone: true,
    imports: [CommonModule, MatTableModule, DatePipe, TranslateModule],
    templateUrl: './metrics.component.html',
    styleUrls: ['./metrics.component.scss']
})
export class MetricsComponent {
    displayedColumns: string[] = ['source_date', 'true_cost', 'impressions', 'ecpm', 'cpc', 'clicks', 'ctr', 'vcr'];
    dataSource = new MatTableDataSource<MetricsModel>();
    currencySignal: Signal<AdvocateCurrencyOptions | undefined> = toSignal(this.utilityService.getCurrencyEffect());
    @Input() set data(data: MetricsModel[] | null | undefined) {
        if (data !== null) {
            this.dataSource = new MatTableDataSource(data);
        }
    }

    constructor(private utilityService: UtilityViewModelService) { }
}